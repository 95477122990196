import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "control", "value" ]

  connect() {
    this._controlHandler = this.update.bind(this)

    const eventTypes = [ "input", "change" ]
    eventTypes.forEach(type => {
      this.controlTarget.addEventListener(type, this._controlHandler)
    })

    this.controlTarget.dispatchEvent(
      new CustomEvent("input", { bubbles: true })
    )
  }

  disconnect() {
    this.controlTarget.removeEventListener("input", this._controlHandler)
    this.controlTarget.removeEventListener("change", this._controlHandler)
  }

  update(e) {
    if (this.hasValueTarget) {
      const { value } = e.target
      this.valueTargets.forEach(el => { el.innerText = value })
    }
  }
}
