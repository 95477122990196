function bindHandler(el) {
  el.addEventListener('click', () => {
    el.classList.toggle('is-active')
    document.getElementById(el.dataset.target).classList.toggle('is-active')
  })
}

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.burger').forEach(bindHandler)
})
