import { Controller } from "stimulus"
import { renderTemplate } from "../../utils"

export default class extends Controller {
  static classes = [ "hidden" ]
  static targets = [ "selection", "options", "template" ]
  static values = {
    inputSelector: String,
    lockingEnabled: { type: Boolean, default: true }
  }

  connect() {
    this.optionsTarget.addEventListener('click', this.select)
  }

  disconnect() {
    this.optionsTarget.removeEventListener('click', this.select)
    clearTimeout(this.lockTimer)
  }

  change({ params }) {
    const content = renderTemplate(this.templateTarget.innerHTML, params)
    this.selectionTarget.innerHTML = Object.entries(params)
      .reduce((m, [ k, v ]) => m.replace(`__${k}__`, v), content)

    if (this.input) {
      this.input.value = params.id
    }
  }

  select = e => {
    if (!e.target.dataset.params) {
      return
    }

    e.preventDefault()

    this.optionsTarget.classList.add(this.hiddenClass)

    const params = JSON.parse(e.target.dataset.params)
    this.change({ params })
    this.dispatch("change", { detail: { src: e.target.href } })

    this.hasSelection = true
    this.lockObject(e.target.dataset.lockUrl)
  }

  lockObject(url) {
    if (!this.lockingEnabledValue) { return }

    this.lockRequest = new AbortController()

    try {
      fetch(url, { signal: this.lockRequest.signal }).catch(console.log)
    } catch (err) {
      console.error(err)
    }

    this.lockTimer = setTimeout(() => this.lockObject(url), 60 * 1000)
  }

  deselect = e => {
    e.preventDefault()
    const { currentTarget } = e

    this.selectionTarget.innerHTML = null
    if (this.input) {
      this.input.value = null
    }

    this.optionsTarget.classList.remove(this.hiddenClass)
    this.dispatch("change", { detail: { src: currentTarget.href } })

    this.cancelLockPolling()
    this.unlockObject(currentTarget.dataset.unlockUrl)
  }

  cancelLockPolling() {
    if (!this.lockTimer) { return }

    try {
      this.lockRequest.abort()
    } catch (err) {
      console.error(err)
    }

    clearTimeout(this.lockTimer)
  }

  unlockObject(url) {
    if (this.lockingEnabledValue) {
      fetch(url)
    }
  }

  get input() {
    return document.querySelector(this.inputSelectorValue)
  }
}
