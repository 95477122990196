import { Controller } from "stimulus"

// https://github.com/goldfire/howler.js/blob/9610df82fb93467d62f25a7b1682d534923dc963/examples/player/player.js#LL263C6-L269C4
function formatTime(secs) {
  const minutes = Math.floor(secs / 60) || 0
  const seconds = (secs - minutes * 60) || 0

  return `${minutes}:${seconds < 10 ? '0' : ''}${Math.round(seconds)}`
}

export default class extends Controller {
  static targets = [
    "media", "panel", "duration", "time",
    "playButton", "pauseButton", "stopButton", "forwardButton", "backwardButton"
  ]

  static classes = [ "hidden" ]

  connect() {
    this.stopButtonTarget.disabled = true
    this.pauseButtonTarget.classList.add(this.hiddenClass)

    this.mediaTarget.addEventListener('play', this.redrawUI)
    this.mediaTarget.addEventListener('pause', this.redrawUI)
    this.mediaTarget.addEventListener('ended', this.redrawUI)
    this.mediaTarget.addEventListener('error', this.redrawUI)
    this.mediaTarget.addEventListener('durationchange', this.handleDurationChange)
    this.mediaTarget.addEventListener('timeupdate', this.handleTimeUpdate)
  }

  disconnect() {
    this.mediaTarget.removeEventListener('play', this.redrawUI)
    this.mediaTarget.removeEventListener('pause', this.redrawUI)
    this.mediaTarget.removeEventListener('ended', this.redrawUI)
    this.mediaTarget.removeEventListener('error', this.redrawUI)
    this.mediaTarget.removeEventListener('durationchange', this.handleDurationChange)
    this.mediaTarget.removeEventListener('timeupdate', this.handleTimeUpdate)
  }

  redrawUI = e => {
    switch (e.type) {
      case 'pause':
        if (!this.mediaTarget.ended) {
          this.mediaTarget.currentTime = Math.max(0.0, this.mediaTarget.currentTime - 3)
        }

        this.pauseButtonTarget.classList.add(this.hiddenClass)
        this.playButtonTarget.classList.remove(this.hiddenClass)
        this.stopButtonTarget.disabled = true
        break
      case 'ended':
        this.pauseButtonTarget.classList.add(this.hiddenClass)
        this.playButtonTarget.classList.remove(this.hiddenClass)
        this.stopButtonTarget.disabled = true
        break
      case 'error':
        this.hidePanels()
        break
      default:
        this.pauseButtonTarget.classList.remove(this.hiddenClass)
        this.playButtonTarget.classList.add(this.hiddenClass)
        this.stopButtonTarget.disabled = false
        break
    }
  }

  handleDurationChange = () => {
    this.durationTarget.innerText = formatTime(this.mediaTarget.duration)
  }

  handleTimeUpdate = () => {
    this.timeTarget.innerText = formatTime(this.mediaTarget.currentTime)
  }

  play() {
    if (this.mediaTarget.readyState == 0) return

    this.mediaTarget.play()
  }

  pause() {
    this.mediaTarget.pause()
  }

  stop() {
    this.mediaTarget.pause()
    this.mediaTarget.currentTime = 0
  }

  forward({ params }) {
    this.mediaTarget.currentTime += Number(params.sec)

    this.mediaTarget.addEventListener('seeked', function seeked(e) {
      e.target.play()
      e.target.removeEventListener('seeked', seeked)
    })
  }

  backward({ params }) {
    this.mediaTarget.currentTime -= Number(params.sec)
  }

  showPanels() {
    this.panelTargets.forEach(
      el => el.classList.remove(this.hiddenClass)
    )
  }

  hidePanels() {
    this.panelTargets.forEach(
      el => el.classList.add(this.hiddenClass)
    )
  }
}
