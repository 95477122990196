/* eslint max-classes-per-file: ["error", 2] */

import { Controller } from "stimulus"
import { xhr, createElement } from "../utils"

const urlValuePlaceholder = encodeURIComponent(":parent_id")

class NullTarget {
  addEventListener() {}
  removeEventListener() {}
  dispatchEvent() {}
}

function optionsForSlimSelect(options, selection, el) {
  const selectedValues = Array.from(selection)
  const frag = el || document.createDocumentFragment()

  options.forEach(o => {
    if (o.options) {
      const optgroup = createElement('optgroup', { label: o.label })
      optionsForSlimSelect(o.options, selection, optgroup)
      frag.append(optgroup)
    } else {
      const textContent = o.text
      const selected = selectedValues.includes(o.value)
      const attrs = { textContent, selected }
      attrs.value = o.value || ""
      if (o.placeholder) attrs['data-placeholder'] = true
      frag.append(createElement('option', attrs))
    }
  })

  return frag
}

function getSelectValues(select) {
  return Array.prototype.filter
    .call(select.options, o => o.selected)
    .map(o => o.value)
}

export default class extends Controller {
  static targets = [ "select" ]

  initialize() {
    const dependsOnSelector = this.data.get("dependsOn")
    this.dependsOnTarget = document.querySelector(dependsOnSelector) || new NullTarget()
    this.changeHandler = e => this.handleChange(e)
  }

  connect() {
    this.dependsOnTarget.addEventListener("change", this.changeHandler)
  }

  disconnect() {
    this.dependsOnTarget.removeEventListener("change", this.changeHandler)
  }

  handleChange(e) {
    if (!this.canHandleChange) { return }

    const selected = getSelectValues(e.currentTarget)
    this.loadData(selected.join(","))
  }

  loadData(values) {
    const url = this.data.get("source").replace(urlValuePlaceholder, values)
    xhr(url).then(r => r.json()).then(d => this.setData(d))
  }

  setData(data) {
    const placeholder = { placeholder: true, text: this.selectTarget.dataset.placeholder }
    const dataWithPlaceholder = [ placeholder ].concat(data)
    const options = optionsForSlimSelect(dataWithPlaceholder, this.preselected)

    this.selectTarget.textContent = ''
    this.selectTarget.append(options)
  }

  get preselected() {
    return `${this.data.get("selected")}`.split(",")
  }

  get canHandleChange() {
    return this.data.get("source").trim() != ""
  }
}
