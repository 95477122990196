import { Controller } from "stimulus"
import filesize from "file-size"
import { renderTemplate, randomString } from "../../utils"
import Uploader from "./uploader"
import templates from "./templates"

export default class extends Controller {
  static targets = [ "container", "zone", "field" ]

  drop(e) {
    e.preventDefault()
    if (e.dataTransfer.items) {
      this._handleItems(e.dataTransfer.items)
    } else {
      this._handleFiles(e.dataTransfer.files)
    }
  }

  addFile(e) {
    this._handleFiles(e.target.files)
    e.target.value = null
  }

  _handleItems(items) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      if (item.kind == "file") {
        this.loadFile(item.getAsFile())
      }
    }
  }

  _handleFiles(files) {
    Array.prototype.slice.call(files).forEach(file => this.loadFile(file))
  }

  highlight() {
    // e.preventDefault()
    this.zoneTarget.classList.add("dropzone--over")
  }

  lowlight() {
    this.zoneTarget.classList.remove("dropzone--over")
  }

  dragOver(e) {
    e.preventDefault()
  }

  loadFile(f) {
    f.uuid = randomString()

    const reader = new FileReader()
    reader.onload = () => {
      this.renderFile({
        name: f.name,
        id: f.uuid,
        size: filesize(f.size).human('si'),
        url: reader.result,
        is_pdf: f.type == "application/pdf",
        is_image: /^image\//.test(f.type),
        is_audio: /^audio\//.test(f.type),
        is_video: /^video\//.test(f.type),
      })
    }
    reader.onerror = () => console.log(reader.error)
    reader.readAsDataURL(f)

    this._upload(f)
  }

  _upload(f) {
    const uploader = new Uploader(this.fieldTarget, f, this.fieldTarget.dataset.directUploadUrl)
    uploader.uploadNow()
  }

  renderFile(file) {
    const div = document.createElement("div")
    div.innerHTML = renderTemplate(templates.doppedItem, file).trim()
    this.containerTarget.appendChild(div.firstChild)
  }
}
