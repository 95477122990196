import { Controller } from "stimulus"

function observeVisibility(element, callback) {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      callback(entry.isIntersecting)
    })
  })

  observer.observe(element)

  return observer
}

export default class extends Controller {
  static targets = [ "sticky" ]

  connect() {
    this.stickyElement = this.stickyTarget
    this.visibilityObserver = observeVisibility(this.element, this.visibilityChanged)
  }

  disconnect() {
    this.visibilityObserver.unobserve(this.element)
  }

  visibilityChanged = visible => {
    if (visible) {
      this.stickyElement.classList.remove("sticky-ghost")
      if (!this.element.contains(this.stickyElement)) {
        this.element.appendChild(this.stickyElement)
      }
    } else {
      document.body.appendChild(this.stickyElement)
      this.stickyElement.classList.add("sticky-ghost")
    }
  }
}
