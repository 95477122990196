import { Controller } from "stimulus"
import TomSelect from "tom-select/dist/js/tom-select.base"
import CheckboxOptions from "tom-select/dist/js/plugins/checkbox_options"
import FlexboxOptions from "../tom-select/flexbox_options"

TomSelect.define("checkbox_options", CheckboxOptions)
TomSelect.define("flexbox_options", FlexboxOptions)

function loadDataCallback(url) {
  let controller = null

  return function loadData(query, callback) {
    controller?.abort()
    controller = new AbortController()

    const headers = { Accept: "application/json" }
    const uri = `${url}?q=${encodeURIComponent(query)}`
    fetch(uri, { headers, signal: controller.signal })
      .then(response => response.json())
      .then(json => callback(json.data))
      .catch(() => callback())
  }
}

export default class extends Controller {
  static values = {
    plugins: { type: Array, default: [ "checkbox_options", "flexbox_options" ] }
  }

  connect() {
    this.options = {}
    this.initTomSelect()
  }

  disconnect() {
    if (this.tomSelect) {
      this.tomSelect.destroy()
    }
  }

  initTomSelect() {
    if (this.element.dataset.tomSelectUrl) {
      this.options = this.#makeRemoteOptions()
    } else {
      this.options = this.#makeOptions()
    }

    if (this.element) {
      this.tomSelect = new TomSelect(this.element, this.options)
    }
  }

  #makeOptions() {
    return {
      maxOptions: 9999,
      plugins: this.pluginsValue
    }
  }

  #makeRemoteOptions() {
    return {
      ...this.#makeOptions(),
      loadingClass: "ts-loading",
      valueField: this.element.dataset.tomSelectValueField,
      labelField: this.element.dataset.tomSelectLabelField,
      searchField: this.element.dataset.tomSelectSearchField,
      shouldLoad: q => q.length > 2,
      load: loadDataCallback(this.element.dataset.tomSelectUrl),
      render: {
        option: (data, escape) => {
          return `<div>${escape(data[this.element.dataset.tomSelectLabelField])}</div>`
        },
        item: (data, escape) => {
          return `<div>${escape(data[this.element.dataset.tomSelectLabelField])}</div>`
        },
      }
    }
  }
}
