import { Controller } from "stimulus"
import Mustache from "mustache"
import { fragment } from "../utils"

export default class extends Controller {
  static targets = [ "container", "item", "template" ]

  initialize() {
    Mustache.parse(this.templateTarget.innerHTML)
  }

  addItem(e) {
    e.preventDefault()
    if (this.itemTargets.length == 0) {
      this.prependItem(e)
    } else {
      this.appendItem(e)
    }
  }

  removeItem(e) {
    e.preventDefault()
    let parent = e.target.parentNode
    while (!parent.matches(`[data-${this.identifier}-target="item"]`)) { parent = parent.parentNode }
    parent.parentNode.removeChild(parent)
  }

  prependItem() {
    this.element.insertBefore(this.newItem, this.element.firstChild)
  }

  appendItem() {
    this.element.insertBefore(this.newItem, this.lastItem.nextSibling)
  }

  get lastItem() {
    return this.itemTargets[this.itemTargets.length - 1]
  }

  get newItem() {
    const markup = this.templateTarget.innerHTML.replace(
      new RegExp(this.data.get('indexPlaceholder'), "g"),
      this.itemTargets.length + 1
    )
    return fragment(markup)
  }
}
