import { Controller } from "stimulus"
import { xhr, showNode, hideNode } from "../utils"

function remoteContentURL(...targets) {
  const urls = targets
    .filter(el => !!el)
    .map(el => el.getAttribute("data-remote-content-url") || el.href || el.value)
    .filter(url => !!url)

  return urls[0]
}

export default class extends Controller {
  static targets = [ "container", "loadingIndicator" ]

  connect() {
    const first = this.element.querySelector(`[data-${this.identifier}-initial]`) ||
      this.element.querySelector(`[data-action="${this.identifier}#load"]`)

    if (first) {
      first.parentNode.classList.add("is-active")
      this.loadURL(remoteContentURL(first))
    }
  }

  load(e) {
    e.preventDefault()
    this.element.querySelectorAll(".is-active").forEach(el => el.classList.remove("is-active"))
    e.target.parentNode.classList.add("is-active")
    this.loadURL(remoteContentURL(e.detail?.target, e.target))
  }

  cancel() {
    this.xhr?.cancel()
  }

  loadURL(url) {
    if (url == null) { return }
    if (url.trim() == "") { return }

    this.xhr?.cancel()

    hideNode(this.containerTarget)
    showNode(this.loadingIndicatorTarget)

    const controller = new AbortController()
    const request = xhr(url, { signal: controller.signal })
      .then(r => r.text())
      .then(t => {
        setTimeout(() => {
          hideNode(this.loadingIndicatorTarget)
          this.containerTarget.innerHTML = t
          showNode(this.containerTarget)
        }, 500)
      })
      .catch(console.error)

    this.xhr = request

    request.cancel = () => controller.abort()
    return request
  }
}
