const displayToggleHandler = e => {
  if (e.target.matches("[data-behavior=display-toggle]")) {
    const showTargets = document.querySelectorAll(e.target.getAttribute("data-display-toggle-selector"))
    const uncheckTargets = document.querySelectorAll(e.target.getAttribute("data-uncheck"))

    if (!e.detail.skip_disable) {
      uncheckTargets.forEach(el => {
        el.disabled = !e.target.checked
      })
    }

    showTargets.forEach(el => {
      el.style.display = e.target.checked ? null : "none"
    })
  }
}

function initState(el) {
  el.dispatchEvent(new CustomEvent("click", { bubbles: true, detail: { skip_disable: true } }))
}

document.addEventListener("click", displayToggleHandler)
document.addEventListener('turbo:load', () => {
  document.querySelectorAll("[data-behavior=display-toggle]").forEach(initState)
})
document.addEventListener('turbo:render', () => {
  document.querySelectorAll("[data-behavior=display-toggle]").forEach(initState)
})
