import { Controller } from "stimulus"
import bb, { line, bar } from "billboard.js"
import { renderTemplate, xhr } from "../utils"

const benchColor = "#ff0000"
const arrayMinValue = array => Math.min(...array)
const numberFormatter = new Intl.NumberFormat(undefined, {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2 })
const formatPercentage = value => numberFormatter.format(value / 100)
const columnTitle = name => (name === "bench" ? "Overall" : name)

const supportedChartTypes = { line, bar }

export default class extends Controller {
  static targets = [ "chart", "tableContainer", "tableTemplate" ]

  connect() {
    this.createChart()
    xhr(this.data.get("url"))
      .then(r => r.json())
      .then(j => this.updateChart(j))
  }

  createChart() {
    const type = this.data.get("type")
    const options = this.chartOptions(type)
    this.chart = bb.generate(options)
  }

  updateChart(data) {
    const type = this.data.get("type")
    this.chart.ygrids(this.createYLines(type))
    if (data.threshold > 0) {
      this.chart.ygrids.add({
        value: data.threshold, text: `${data.threshold}%`, class: 'trend-chart-threshold'
      })
    }

    this.chart.unload()
    this.chart.load({ columns: data.chart_data })

    if (this.hasTableContainerTarget) {
      this.tableContainerTarget.innerHTML = this.renderTable(data.chart_data)
    }
  }

  renderTable(data) {
    const obj = {
      headers: data[0].splice(1),
      rows: data.splice(1).map(row => {
        return row.slice(0, 1).map(columnTitle).concat(row.slice(1).map(formatPercentage))
      })
    }
    return renderTemplate(this.tableTemplateTarget.innerText, obj)
  }

  axisType(chartType) {
    return {
      line: "category",
      bar: "category"
    }[chartType]
  }

  chartTicks(type) {
    return {
      bar: [ 0, 20, 40, 60, 80, 100 ],
      line: [ 0, 20, 40, 60, 80, 100 ]
    }[type]
  }

  chartYMin(data) {
    const minValues = data.slice(1).map(d => arrayMinValue(d.slice(1)))
    return arrayMinValue(minValues)
  }

  chartOptions(type) {
    return {
      bindto: this.chartTarget,
      data: {
        x: 'x',
        columns: [],
        type: supportedChartTypes[type](),
        types: {
          bench: line()
        },
        classes: {
          bench: "bench-line"
        },
        names: {
          bench: columnTitle("bench")
        },
        labels: { format: formatPercentage },
        color: (color, d) => (d === "bench" || d.id === "bench" ? benchColor : color),
        empty: { label: { text: "Keine Daten" } }
      },
      axis: {
        x: {
          type: this.axisType(type)
        },
        y: {
          max: 100,
          tick: {
            values: this.chartTicks(type),
            format(d) { return `${d}%` }
          }
        }
      },
      legend: {
        usePoint: true
      },
      color: {
        pattern: [ '#bf5257', '#90b4de', '#b5d294', '#e4b871', '#c89ac8' ]
      },
      padding: {
        bottom: 50,
        right: 20,
        left: 35,
        top: 20
      },
      grid: {
        lines: { front: false },
        y: {
          lines: this.createYLines(type)
        }
      },
      bar: {
        space: 0.2
      }
    }
  }

  createYLines(chartType) {
    const lines = []
    const ticks = this.chartTicks(chartType) || []
    ticks.forEach(t => lines.push({ value: t, class: 'trend-chart-tick' }))
    return lines
  }
}
