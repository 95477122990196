import { Controller } from "stimulus"
import { ohop } from "../utils"

function fieldValueProp(el) {
  if (el.type == "checkbox") { return "checked" }
  if (el.type == "radio") { return "checked" }
  return "value"
}

function fieldProps(el) {
  return ohop(el, "defaultValue") ?
    [ "value", "defaultValue" ] :
    [ fieldValueProp(el), `${el.type}DefaultValue` ]
}

export default class extends Controller {
  connect() {
    this.memoize()
  }

  reset() {
    this.fields.forEach(field => {
      const [ valueProp, defaultProp ] = fieldProps(field)
      field[valueProp] = field[defaultProp]
    })
  }

  memoize() {
    this.fields.forEach(field => {
      const [ valueProp, defaultProp ] = fieldProps(field)
      field[defaultProp] = field[valueProp]
    })
  }

  get fields() {
    return [ ...this.element.querySelectorAll("input, textarea") ]
  }
}
