const dropdownSelector = '.dropdown:not(.is-hoverable)'

const closeDropdowns = () => {
  document
    .querySelectorAll(dropdownSelector)
    .forEach(el => el.classList.remove('is-active'))
}

function openDropdown(e) {
  e.stopPropagation()
  e.target.closest(dropdownSelector).classList.toggle('is-active')
}

document.addEventListener('click', () => closeDropdowns())

document.addEventListener('click', e => {
  if (e.target.closest('.dropdown-trigger')) {
    openDropdown(e)
  }
})
