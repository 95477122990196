import { Controller } from "stimulus"
import { showNode, hideNode } from "../utils"

export default class extends Controller {
  static targets = [ "template", "section", "container", "template", "deleteTemplate" ]

  addSection() {
    const newNode = this.createNode()
    this.containerTarget.appendChild(newNode)
  }

  removeSection(e) {
    let sectionNode = e.currentTarget.parentNode
    while (sectionNode.getAttribute(`data-${this.identifier}-target`) != "section" || sectionNode.parentNode == null) {
      sectionNode = sectionNode.parentNode
    }

    const sectionIndex = this.sectionTargets.indexOf(sectionNode)
    const isVirtualSection = sectionNode.hasAttribute(`data-${this.identifier}-virtual`)
    const destroyField = sectionNode.querySelector(`.${this.identifier}-destroy-field`)

    if (isVirtualSection) {
      this.log(`removing virtual section ${sectionIndex}`, sectionNode)
      sectionNode.parentNode.removeChild(sectionNode)
    } else {
      this.log(`marking section ${sectionIndex} for destruction`, sectionNode)
      destroyField.selected = true
      destroyField.value = 1

      const messageNode = this.createDestroyNode()
      console.log(messageNode.querySelector('.js-restore'))
      messageNode.querySelector('.js-restore').addEventListener('click', () => {
        console.log("restore")
        this.restore(destroyField, sectionNode, messageNode)
      })
      sectionNode.parentNode.insertBefore(messageNode, sectionNode)
      hideNode(sectionNode)
    }
  }

  restore(f, s, m) {
    f.selected = false
    f.value = null

    showNode(s)
    m.parentNode.removeChild(m)
  }

  adjustAttributes(str) {
    return str.replace(
      new RegExp(this.data.get('association'), "g"),
      this.sectionTargets.length
    )
  }

  createNode() {
    const div = document.createElement("div")
    div.innerHTML = this.adjustAttributes(this.templateTarget.innerHTML)
    div.firstChild.setAttribute(`data-${this.identifier}-virtual`, true)

    return div.firstChild
  }

  createDestroyNode() {
    const el = document.createElement("div")
    el.innerHTML = this.deleteTemplateTarget.innerHTML
    return el.firstChild
  }

  log(...args) {
    console.log(`${this.identifier}: `, ...args)
  }
}
