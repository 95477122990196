import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = [ "positionField" ]

  connect() {
    const options = {
      group: this.element.dataset.sortableGroup,
      handle: this.element.dataset.sortableHandle,
      onEnd: () => this.#syncPosition()
    }
    this.sortable = new Sortable(this.element, options)
  }

  disconnect() {
    this.sortable.destroy()
  }

  #syncPosition() {
    console.log(this.hasPositionFieldTarget)
    if (this.hasPositionFieldTarget) {
      this.positionFieldTargets.forEach((n, i) => { n.value = i })
    }
  }
}
