import { Controller } from "stimulus"

function buildURL(name, value) {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)

  params.set(name, value)
  url.search = params.toString()

  return url.toString()
}

export default class extends Controller {
  apply() {
    const { name, value } = this.element
    Turbolinks.visit(buildURL(name, value))
  }
}
