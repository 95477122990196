import { Controller } from "stimulus"
import Mustache from "mustache"
import { clearNode, arrayMove } from "../utils"

const serialize = o => JSON.stringify(o)
const deserialize = s => { try { return JSON.parse(s) } catch (e) { return [] } }

export default class extends Controller {
  static values = { persistable: { type: Boolean, default: false }, duplicateError: String }
  static classes = [ "validationError" ]
  static targets = [
    "renderContainer", "optionTemplate", "newOptionTemplate",
    "addLabel", "addValue", "stateField", "statusBar", "statusBarTemplate",
    "error",
    "labelField", "valueField"
  ]

  initialize() {
    this.state = deserialize(this.stateFieldTarget.value) || []
    Mustache.parse(this.optionTemplateTarget.innerHTML)
  }

  connect() {
    this._form = this.element.closest("form")
    this.renderTable()

    this.labelFieldTargets.forEach(f => f.addEventListener("change", this.update))
    this.valueFieldTargets.forEach(f => f.addEventListener("change", this.update))
  }

  disconnect() {
    this.labelFieldTargets.forEach(f => f.removeEventListener("change", this.update))
    this.valueFieldTargets.forEach(f => f.removeEventListener("change", this.update))
  }

  update = () => {
    const newState = []
    this.labelFieldTargets.forEach((f, i) => {
      newState.push({ label: f.value, value: this.valueFieldTargets[i].value })
    })
    this.state = newState
    this.saveState()
  }

  add(e) {
    e.preventDefault()

    const opt = {
      label: this.addLabelTarget.value.trim(),
      value: this.addValueTarget.value.trim()
    }
    this.resetError()

    this.validate(opt).then(() => {
      this.state.push(opt)
      this.saveState()
      this.renderTable()
    }).catch(error => {
      this.showError(error)
    })
  }

  validate(option) {
    return new Promise((resolve, reject) => {
      const values = this.state.map(o => o.value)
      if (values.includes(option.value)) {
        reject(this.duplicateErrorValue)
      } else {
        resolve()
      }
    })
  }

  showError(message) {
    this.addLabelTarget.classList.add(...this.validationErrorClasses)
    this.addValueTarget.classList.add(...this.validationErrorClasses)
    this.errorTarget.style.display = null
    this.errorTarget.textContent = message
  }

  resetError() {
    this.addLabelTarget.classList.remove(...this.validationErrorClasses)
    this.addValueTarget.classList.remove(...this.validationErrorClasses)
    this.errorTarget.style.display = "none"
    this.errorTarget.textContent = ""
  }

  remove(e) {
    const { index } = e.currentTarget.dataset
    this.state.splice(index, 1)
    this.saveState()
    this.renderTable()
  }

  moveUp(e) {
    e.preventDefault()
    const index = Number(e.currentTarget.dataset.index)
    this.state = arrayMove(this.state, index, index - 1)
    this.saveState()
    this.renderTable()
  }

  moveDown(e) {
    e.preventDefault()
    const index = Number(e.currentTarget.dataset.index)
    this.state = arrayMove(this.state, index, index + 1)
    this.saveState()
    this.renderTable()
  }

  renderTable() {
    clearNode(this.renderContainerTarget)
    const template = this.optionTemplateTarget.innerHTML
    this.state.forEach((item, index, state) => {
      const data = { label: item.label, value: item.value, index }
      data.isFirst = index == 0
      data.isLast = index == state.length - 1
      const row = this.createRow(Mustache.render(template, data))
      this.renderContainerTarget.appendChild(row)
    })
    this.renderAddOption()
    this.renderStatusBar()
  }

  renderAddOption() {
    const row = this.createRow(this.newOptionTemplateTarget.innerHTML)
    this.renderContainerTarget.appendChild(row)
  }

  renderStatusBar() {
    const data = { count: this.state.length }
    const rendered = Mustache.render(this.statusBarTemplateTarget.innerHTML, data)
    this.statusBarTarget.innerHTML = rendered
  }

  createRow(markup) {
    const div = document.createElement("tbody")
    div.innerHTML = markup
    return div.firstChild
  }

  saveState() {
    this.stateFieldTarget.value = serialize(this.state)
    this.persistState()
  }

  persistState() {
    if (this.isPersistable) {
      this._form.requestSubmit()
    }
  }

  get isPersistable() {
    return this.persistableValue && this._form
  }
}
