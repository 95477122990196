import { Controller } from "stimulus"

function setAttr(elements, attr, value) {
  [ ...elements ].forEach(el => { el[attr] = value })
}

export default class extends Controller {
  static targets = [ "modal", "input" ]

  initialize() {
    this.element.addEventListener("autocomplete.change", this.open.bind(this))
  }

  open(e) {
    setAttr(
      this.modalTarget.querySelectorAll('.modal-card-title'),
      'innerText',
      e.detail.textValue
    )
    setAttr(
      this.modalTarget.querySelectorAll('#employee_evaluation_setting_employee_id'),
      'value',
      e.detail.value
    )

    this.modalTarget.classList.add("is-active")
    this.inputTarget.value = ""
  }
}
