import { Controller } from "stimulus"
import { showNode, hideNode } from "../utils"

export default class extends Controller {
  static targets = [ "field", "utilityField" ]

  connect() {
    this.availableUtilities = this.utilityFieldTarget.getAttribute('data-utility-field-for').split(' ')
    setTimeout(this.triggerChange.bind(this), 10)
  }

  triggerChange() {
    this.fieldTarget.dispatchEvent(new Event("change"))
  }

  showUtilityField(e) {
    const kind = e.currentTarget.value
    if (this.availableUtilities.includes(kind)) {
      showNode(this.utilityFieldTarget)
    } else {
      hideNode(this.utilityFieldTarget)
    }
  }
}
