import { DirectUpload } from "@rails/activestorage"

class Uploader {
  constructor(input, file, url) {
    this.input = input
    this.uploadId = file.uuid
    this.upload = new DirectUpload(file, url, this)
  }

  uploadNow() {
    this.upload.create((error, blob) => {
      if (error) {
        const el = document.getElementById(`direct-upload-${this.uploadId}`)
        el.classList.add("direct-upload--error")
      } else {
        const hiddenField = document.getElementById(`direct-upload-field-${this.uploadId}`)
        hiddenField.setAttribute("value", blob.signed_id)
        hiddenField.name = this.input.name
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener(
      "progress",
      event => this.directUploadDidProgress(event)
    )
  }

  directUploadDidProgress(event) {
    const progressEl = document.getElementById(`direct-upload-progress-${this.uploadId}`)
    const progress = (event.loaded / event.total) * 100
    progressEl.style.width = `${progress}%`

    if (progress < 100) {
      document.getElementById(`direct-upload-${this.uploadId}`).style.display = null
    } else {
      document.getElementById(`direct-upload-${this.uploadId}`).style.display = 'none'
    }
  }
}

export default Uploader
