import { Controller } from "stimulus"
import { showNode, hideNode } from "../utils"

const modeEvents = {
  hover: [ "mouseenter", "mouseleave" ]
}

export default class extends Controller {
  static targets = [ "item" ]

  initialize() {
    const eventNames = modeEvents[this.data.get("mode")] || [ "click" ]
    for (let i = eventNames.length - 1; i >= 0; i--) {
      this.element.addEventListener(eventNames[i], this.toggle.bind(this))
    }
  }

  connect() {
    this.itemTargets.forEach((el, i) => {
      if (el.getAttribute(`data-${this.identifier}-initial`)) {
        this.currentIndex = i
        showNode(el)
      } else {
        hideNode(el)
      }
    })

    if (this.currentIndex === undefined) {
      showNode(this.itemTargets[0])
      this.currentIndex = 0
    }
  }

  toggle(event) {
    event.preventDefault()
    hideNode(this.itemTargets[this.currentIndex])
    this.currentIndex = (this.currentIndex + 1 + this.itemTargets.length) % this.itemTargets.length
    showNode(this.itemTargets[this.currentIndex])
  }

  hideAll() {
    this.itemTargets.forEach(hideNode)
  }
}
