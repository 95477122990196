import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "destroyField" ]

  disconnect() {
    document.documentElement.classList.remove("is-clipped")
  }

  show(e) {
    e.stopPropagation()

    this.modalTarget.classList.add("is-active")
    document.documentElement.classList.add("is-clipped")
  }

  hide(e) {
    e.stopPropagation()

    this.modalTarget.classList.remove("is-active")
    document.documentElement.classList.remove("is-clipped")
  }

  destroy(e) {
    e.preventDefault()
    if (this.data.get("persisted") == "true") {
      this.destroyPersisted()
    } else {
      this.destroyTransient()
    }
  }

  destroyPersisted() {
    const el = document.createElement("div")
    el.innerHTML = `<div class="destroyed-item js-sheet-asset-destroy-message">
      <p class="destroyed-item__message">
        <span>Wird beim Speichern gelöscht.</span>
        <button data-action="sheet-asset#restore">Rückgängig</button>
      </p>
    </div>`
    this.destroyFieldTarget.value = this.data.get("id")
    this.element.style.position = "relative"
    this.element.appendChild(el.firstChild)
  }

  destroyTransient() {
    this.element.parentNode.removeChild(this.element)
  }

  restore(e) {
    e.preventDefault()
    this.destroyFieldTarget.value = null
    const el = this.element.querySelector(".js-sheet-asset-destroy-message")
    this.element.removeChild(el)
  }
}
