import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "field", "state" ]

  edit(e) {
    e.preventDefault()
    this.modalTarget.classList.add("is-active")
  }

  save(e) {
    e.preventDefault()
    this.stateTarget.innerText = this.fieldTarget.value
    this.modalTarget.classList.remove("is-active")
  }
}
