import { Controller } from "stimulus"
import { showNode, hideNode, isVisible } from "../utils"

export default class extends Controller {
  static values = {
    collapsed: Boolean,
    restoreIdentifier: String
  }

  static targets = [ "container" ]

  initialize() {
    this.collapsedClassName = this.data.get("collapsedClassName") || "collapsable__collapsed"
  }

  connect() {
    this.restoreState()
    this.collapsedValue && this.hide()
  }

  show(event) {
    showNode(this.containerTarget)
    this.element.classList.remove(this.collapsedClassName)

    if (event) {
      event.preventDefault()
      if (event.currentTarget.matches(".button")) {
        event.currentTarget.classList.add("is-active")
      }
    }

    this.saveState()
  }

  hide(event) {
    hideNode(this.containerTarget)
    this.element.classList.add(this.collapsedClassName)

    if (event) {
      event.preventDefault()
      if (event.currentTarget.matches(".button")) {
        event.currentTarget.classList.remove("is-active")
      }
    }

    this.saveState()
  }

  toggle(event) {
    if (isVisible(this.containerTarget)) {
      return this.hide(event)
    }
    return this.show(event)
  }

  saveState() {
    if (!this.hasRestoreIdentifierValue) return

    localStorage.setItem(this.stateKey, this.collapsed)
  }

  restoreState() {
    if (!this.hasRestoreIdentifierValue) return
    if (localStorage.getItem(this.stateKey) === null) return

    this.collapsedValue = localStorage.getItem(this.stateKey) === "true"
  }

  get collapsed() {
    return this.element.classList.contains(this.collapsedClassName)
  }

  get stateKey() {
    return `${this.identifier}--${this.restoreIdentifierValue}`
  }
}
