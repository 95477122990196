import { Controller } from "stimulus"
import Papa from "papaparse"
import { renderTemplate, showNode, hideNode } from "../utils"

const applyIndex = array => {
  for (let i = array.length - 1; i >= 0; i--) {
    array[i].index = i
  }
  return array
}

export default class extends Controller {
  static targets = [ "fileField", "preview", "previewTemplate", "previewContainer" ]

  connect() {
    hideNode(this.previewContainerTarget)
  }

  showCsv(e) {
    const file = e.currentTarget.files[0]
    this.parse(file)
  }

  parse(file) {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: result => this.render(result.data)
    })
  }

  render(rows) {
    this.previewTarget.innerHTML = this.renderTemplate({ rows: applyIndex(rows) })
    showNode(this.previewContainerTarget)
  }

  renderTemplate(data) {
    return renderTemplate(this.previewTemplateTarget.innerText, data)
  }
}
