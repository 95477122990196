import { Controller } from "stimulus"

const warnings = {
  de: "Ihre Änderungen im Formular auf dieser Seite werden verloren gehen. Möchten Sie wirklich fortfahren?",
  en: "Your changes in the form on this page will be lost. Are you sure to continue?"
}

export default class extends Controller {
  initialize() {
    this.changed = false
    this.submitting = false
  }

  connect() {
    this.element.addEventListener("submit", this.submit)
    this.element.addEventListener("change", this.change)
    document.addEventListener("click", this.warn)
  }

  disconnect() {
    this.element.removeEventListener("submit", this.submit)
    this.element.removeEventListener("change", this.change)
    document.removeEventListener("click", this.warn)
  }

  submit = () => {
    this.submitting = true
  }

  change = e => {
    if (e.target.matches(`[data-${this.identifier}-ignore]`)) return
    this.changed = true
  }

  warn = e => {
    if (!e.target.closest("a")) return
    if (this.submitting) return
    if (!this.changed) return

    if (!window.confirm(this.message)) {
      e.preventDefault()
    }
  }

  get message() {
    return warnings[document.documentElement.lang || "de"]
  }
}
