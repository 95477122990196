import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container", "template", "item", "removeButton" ]

  initialize() {
    const indexStub = this.data.get("indexStub") || "NEW_NESTED_FORM_INDEX"
    this.indexStubRe = new RegExp(indexStub, "g")
  }

  addItem() {
    const node = this.createNode(this.itemTargets.length)
    this.containerTarget.appendChild(node)
  }

  removeItem(e) {
    const index = this.removeButtonTargets.indexOf(e.currentTarget)
    const itemNode = this.itemTargets[index]
    itemNode.parentNode.removeChild(itemNode)
  }

  createNode(i) {
    const div = document.createElement("div")
    div.innerHTML = this.templateTarget.innerHTML.replace(this.indexStubRe, i)
    return div.firstChild
  }
}
