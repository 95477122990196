import { Controller } from "stimulus"
import { debounce } from "../utils"

export default class extends Controller {
  initialize() {
    this.debouncedSubmit = debounce(this.submit, 350)
  }

  submit = () => {
    this.element.requestSubmit()
  }
}
