import { Controller } from "stimulus"
// import { renderTemplate, showNode, hideNode } from "utils"

export default class extends Controller {
  static targets = [ "checkBox" ]
  static values = { limit: Number }

  connect() {
    this.handle()
  }

  handle() {
    const disable = this.tickedCheckBoxes.length > 0 &&
      this.tickedCheckBoxes.length >= this.tickedLimit

    this.checkBoxTargets.forEach(el => {
      if (this.validTickedCheckBoxes.indexOf(el) > -1) {
        el.disabled = false
      } else {
        el.disabled = disable
      }
    })
  }

  limitValueChanged() {
    this.handle()
  }

  get validTickedCheckBoxes() {
    return this.tickedCheckBoxes.slice(0, this.tickedLimit)
  }

  get tickedCheckBoxes() {
    return this.checkBoxTargets.filter(el => el.checked)
  }

  get tickedLimit() {
    return this.limitValue > 0 ? this.limitValue : this.checkBoxTargets.length
  }
}
