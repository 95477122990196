import Flatpickr from "stimulus-flatpickr"
import en from "flatpickr/dist/l10n/default"
import { German as de } from "flatpickr/dist/l10n/de"
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

en.rangeSeparator = ' — '
de.rangeSeparator = ' — '

const locales = { en, de }

export default class extends Flatpickr {
  static targets = [ "clearButton" ]

  initialize() {
    super.initialize()
    this.config = { locale: locales[document.documentElement.lang || 'de'] }
    if (this.data.get("monthSelect")) {
      const monthSelect = new MonthSelectPlugin({ dateFormat: "Y-m" })
      this.configurePlugins(plugins => plugins.push(monthSelect))
    }

    this.clear = this.clear.bind(this)
  }

  connect() {
    super.connect()
    if (this.hasClearButtonTarget) {
      this.clearButtonTarget.addEventListener("click", this.clear)
    }
  }

  disconnect() {
    super.disconnect()
    if (this.hasClearButtonTarget) {
      this.clearButtonTarget.removeEventListener("click", this.clear)
    }
  }

  configurePlugins(cb) {
    const plugins = this.config.plugins || []
    cb(plugins)
    this.config.plugins = plugins
  }

  ready(selectedDates, dateStr, instance) {
    this._toggleClearButton(dateStr)
    this.autoOpenEnabled && instance.open()
  }

  change(selectedDates, dateStr) {
    this._toggleClearButton(dateStr)
  }

  clear(e) {
    e.preventDefault()
    this.fp.clear()
  }

  _toggleClearButton(dateStr) {
    if (this.hasClearButtonTarget) {
      this.clearButtonTarget.style.display = dateStr ? null : "none"
    }
  }

  get autoOpenEnabled() {
    return [ "open", "true" ].indexOf(this.data.get("open")) >= 0
  }
}
