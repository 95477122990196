import { Controller } from "stimulus"
import { renderTemplate, fragment, clearNode } from "../utils"

export default class extends Controller {
  static targets = [ "items", "itemsTemplate", "input" ]

  initialize() {
    this.element.addEventListener("autocomplete.change", this.addItem.bind(this))
  }

  connect() {
    this.items = JSON.parse(this.data.get("items"))
    this.render()
  }

  addItem(e) {
    e.preventDefault()
    const item = this.itemFromEvent(e)

    if (this.itemExists(item.id)) return

    this.items.push(item)
    this.render()

    if (this.hasInputTarget) {
      this.inputTarget.value = null
    }
  }

  removeItem(e) {
    e.preventDefault()
    const selector = `[data-${this.identifier}-uid]`
    let el = e.target
    while (!el.matches(selector) && el.parentNode) {
      el = el.parentNode
    }
    for (let i = this.items.length - 1; i >= 0; i--) {
      const item = this.items[i]
      if (el.dataset.collectionUid == item.id) {
        this.items.splice(i, 1)
        break
      }
    }
    this.render()
  }

  itemExists(id) {
    return this.items.map(i => i.id.toString()).includes(id)
  }

  render() {
    const data = { items: this.items.sort(this.sortItems.bind(this)) }
    const newItem = renderTemplate(this.itemsTemplateTarget.innerHTML, data)
    clearNode(this.itemsTarget)
    this.itemsTarget.appendChild(fragment(newItem))
  }

  itemFromEvent(e) {
    return { id: e.detail.value, title: e.detail.textValue }
  }

  sortItems(a, b) {
    const titleA = a.title.toLowerCase()
    const titleB = b.title.toLowerCase()
    if (titleA < titleB) return -1
    if (titleA > titleB) return 1
    return 0
  }
}
