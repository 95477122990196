import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    formSelector: String,
    buttonSelector: { type: String, default: '#mass-destruction' }
  }

  static targets = [ "checkbox", "allCheckbox", "button", "form" ]

  connect() {
    if (this.hasButtonTarget) {
      this._button = this.buttonTarget
    } else {
      this._button = document.querySelector(this.buttonSelectorValue)
    }

    this.changed()
    this.checkboxTargets.forEach(el => el.addEventListener("change", this.changed.bind(this)))

    if (this.hasAllCheckboxTarget) {
      this.allCheckboxTarget.addEventListener("change", this.toggleAll.bind(this))
    }
  }

  changed() {
    this.toggleButtonDisabled()
    this.allCheckboxTarget.checked = this.checkboxTargets.every(el => el.checked)
  }

  toggleAll() {
    this.checkboxTargets.forEach(el => { el.checked = this.allCheckboxTarget.checked })
    this.toggleButtonDisabled()
  }

  toggleButtonDisabled() {
    this._button.disabled = !this.checkboxTargets.some(el => el.checked)
  }

  get formElement() {
    if (this.hasFormTarget) {
      return this.formTarget
    }
    return document.querySelector(this.formSelectorValue)
  }
}
