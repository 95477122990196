import { Controller } from "stimulus"
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'

export default class extends Controller {
  static targets = [ "canvas" ]

  save(e) {
    e.preventDefault()

    html2canvas(this.canvasTarget, this.options).then(canvas => {
      canvas.toBlob(blob => {
        saveAs(blob, "report.png")
      })
    })
  }

  get options() {
    return {
      width: this.canvasTarget.scrollWidth,
      height: this.canvasTarget.scrollHeight,
      scrollY: 0
    }
  }
}
