import { Controller } from "stimulus"
import { showNode, hideNode, isVisible } from "../utils"

function getLabel(el, state) {
  try {
    const labels = JSON.parse(el.dataset.visibilityToggleLabels)
    const key = JSON.stringify(state)
    return labels[key]
  } catch (e) {
    return null
  }
}

export default class extends Controller {
  static values = {
    state: { type: Boolean, default: true },
    buttonEvent: { type: String, default: "click" }
  }

  static targets = [ "member", "button" ]

  initialize() {
    this.buttonClickHandler = this.toggle.bind(this)
  }

  connect() {
    this.buttonTargets.forEach(b => {
      b.addEventListener(this.buttonEventValue, this.buttonClickHandler)
    })
    this.buttonTargets.forEach(b => this.setButtonLabel(b, this.stateValue))
    this.applyState()
  }

  disconnect() {
    this.buttonTargets.forEach(b => {
      b.removeEventListener(this.buttonEventValue, this.buttonClickHandler)
    })
  }

  applyState() {
    this.safeMemberTargets.forEach(m => {
      this.stateValue ? showNode(m) : hideNode(m)
    })
  }

  toggle(e) {
    e.preventDefault()
    this.safeMemberTargets.forEach(m => (isVisible(m) ? hideNode(m) : showNode(m)))
    if (this.hasMemberTarget) {
      this.setButtonLabel(e.target, isVisible(this.memberTarget))
    }
  }

  setButtonLabel(el, state) {
    const label = getLabel(el, state) || el.textContent
    el.textContent = label.replace(":count", this.memberTargets.length)
  }

  get safeMemberTargets() {
    return this.hasMemberTargets ? this.memberTargets : []
  }
}
