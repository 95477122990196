import { Controller } from "stimulus"

document.addEventListener('click', e => {
  if (e.target.matches("[data-behavior~=destroyable-cancel]")) {
    e.target.dispatchEvent(new CustomEvent('destroyable.cancel', { bubbles: true }))
  }
})

export default class extends Controller {
  static targets = [ "checkBox", "message" ]

  initialize() {
    const el = document.createElement("div")
    el.innerHTML = `
    <div class="destroyable__message" data-destroyable-target="message">
      <span>${this.data.get("message")}</span>
      <p><button data-action="destroyable#restore">${this.data.get("restoreLabel")}</button></p>
    </div>
    `.trim()
    this.cover = el.firstChild
  }

  destroy(e) {
    e.preventDefault()
    this.checkBoxTarget.value = "1"
    this.element.classList.add("destroyable--destroyed")
    this.element.appendChild(this.cover.cloneNode(true))
  }

  restore(e) {
    e.preventDefault()
    this.checkBoxTarget.value = null
    this.element.classList.remove("destroyable--destroyed")
    if (this.hasMessageTarget) {
      this.messageTarget.remove()
    }
  }
}
