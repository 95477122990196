import { Controller } from "stimulus"

const rolesWithUnlimitedClients = [ "holyness", "admin", "qa" ]
const roleClientsLimit = role => (rolesWithUnlimitedClients.indexOf(role) === -1 ? 1 : 0)

export default class extends Controller {
  static targets = [ "clientsCheckBoxes" ]

  roleChanged(e) {
    this.updateClientsLimit(e.target.value)
  }

  updateClientsLimit(role) {
    if (!this.hasClientsCheckBoxesTarget) return

    const limit = roleClientsLimit(role)
    this.clientsCheckBoxesTarget.setAttribute("data-check-box-group-limit-value", limit)
  }
}
