import sparkline from "../vendor/sparkline"

const defaults = { interactive: false }

function createSparkline(el) {
  const values = JSON.parse(el.dataset.values)
  sparkline(el, values, defaults)
}

const observer = new MutationObserver(mutations => {
  mutations.forEach(mutation => {
    for (let i = 0; i < mutation.addedNodes.length; i++) {
      (() => {
        const node = mutation.addedNodes[i]
        if (node.nodeName != "SVG") return
        if (node.matches(".sparkline")) {
          createSparkline(node)
        }
      })()
    }
  })
})
observer.observe(document, { childList: true, subtree: true })

document.addEventListener("turbo:load", () => {
  Array.from(document.querySelectorAll("svg.sparkline")).forEach(createSparkline)
})
