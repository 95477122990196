import { once } from './utils'

function findModal(selector) {
  return document.querySelector(selector) ||
    document.querySelector(`#${selector}`)
}

document.addEventListener("turbo:visit", () => {
  document.documentElement.classList.remove("is-clipped")
})

document.addEventListener('click', e => {
  const modalTarget = e.target.closest("[data-modal]")
  if (modalTarget) {
    e.preventDefault()

    document.documentElement.classList.add("is-clipped")

    const modal = findModal(modalTarget.getAttribute("data-modal"))
    modal.classList.add("is-active", "modal--show-animation")
    modal.classList.remove("modal--hide-animation")

    if (e.target.dataset.modalSrc) {
      modal.querySelector("turbo-frame[src]").src = e.target.dataset.modalSrc
    }

    const event = new CustomEvent("modal:open", { detail: { target: modalTarget } })
    modal.dispatchEvent(event)
  }

  if (e.target.matches(".modal-close, .modal-background, [data-behavior~=modal-close]")) {
    e.preventDefault()
    let parent = e.target.parentNode
    while (!parent.matches(".modal")) { parent = parent.parentNode }

    once(parent, 'animationend', () => parent.classList.remove("is-active"))
    parent.classList.add("modal--hide-animation")
    parent.classList.remove("modal--show-animation")

    document.documentElement.classList.remove("is-clipped")

    const event = new CustomEvent("modal:close")
    parent.dispatchEvent(event)
  }
})
