import { Controller } from "stimulus"
import { setCookie, deleteCookie } from "../utils"

export default class extends Controller {
  static values = { cookieName: String }

  toggle(e) {
    if (e.currentTarget.checked) {
      setCookie(this.cookieNameValue, e.currentTarget.value)
    } else {
      deleteCookie(this.cookieNameValue)
    }
  }
}
