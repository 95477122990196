const expandMoreHandler = e => {
  const target = e.target.closest("[data-behavior=expand-more]")
  if (target) {
    const selector = target.getAttribute("data-expand-more-selector")
    const toggleClass = target.getAttribute("data-expand-more-toggle-class")
    const elements = [ ...document.querySelectorAll(selector) ]

    elements.forEach(el => el.classList.remove(toggleClass))
    target.classList.add(toggleClass)

    e.preventDefault()
  }
}

document.addEventListener("click", expandMoreHandler)
