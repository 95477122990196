import { Controller } from "stimulus"
import { setCookie } from "../../utils"

export default class extends Controller {
  static targets = [ "controller" ]

  switch({ target, params }) {
    if (!this.hasControllerTarget) { return }

    setCookie(this.identifier, params.type)

    const children = [ ...target.parentNode.children ]
    children.forEach(el => {
      el.classList.remove("is-selected", "is-active")
    })
    target.classList.add("is-selected", "is-active")

    this.controllerTargets.forEach(controller => {
      controller.setAttribute("data-dashboard-site-chart-type-value", params.type)
    })
  }
}
