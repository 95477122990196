// https://gist.github.com/glaszig/35e18880f77a16073e2ab6f28a14880d

function createElement(name, props, children) {
  const el = document.createElement(name)
  Object.keys(props || {}).forEach(prop => { el[prop] = props[prop] })
  children.forEach(child => {
    const node = (child instanceof Array) ? createElement(...child) : child
    el.appendChild(node)
  })

  return el
}

function wrapWithFlexbox(el, props) {
  const children = Array.from(el.childNodes).map(
    node => [ 'div', null, [ node ] ]
  )

  const wrapper = createElement('div', props, children)
  el.appendChild(wrapper)

  return el
}

function renderWithFlexbox(render, options) {
  return (html, escape) => {
    return wrapWithFlexbox(
      render(html, escape),
      { className: options.class, style: options.style }
    )
  }
}

export default function flexboxOptions(options) {
  options.class = options.class || "ts-option-wrapper"

  this.hook('after', 'setupTemplates', () => {
    const renderWithoutFlexbox = this.settings.render.option
    const renderer = renderWithFlexbox(renderWithoutFlexbox, options)
    this.settings.render.option = renderer
  })
}
