import { Controller } from "stimulus"
import { renderTemplate, isEmptyNode, showNode, hideNode, xhr, fragment } from "../utils"

export default class extends Controller {
  static targets = [ "container", "collection", "template", "loadingTemplate" ]

  selection = []

  connect() {
    if (isEmptyNode(this.collectionTarget)) {
      hideNode(this.containerTarget)
    }
  }

  show({ currentTarget: selectBox }) {
    this.showLoading()
    this.collectionTarget.innerHTML = ''

    const options = Array.from(selectBox.selectedOptions)
    this._load(options)
  }

  change({ currentTarget: input }) {
    if (input.checked) {
      this.selection = [ ...this.selection, input.value ]
    } else {
      const index = this.selection.indexOf(input.value)
      this.selection = index === -1 ? this.selection : this.selection.slice(index, 1)
    }
  }

  showLoading() {
    this.collectionTarget.innerHTML = this.loadingTemplateTarget.innerHTML
  }

  render(title, data) {
    showNode(this.containerTarget)

    const node = fragment(this._render(title, data))
    this.applySelection(node)

    this.collectionTarget.appendChild(node)
  }

  checkFirst(context) {
    const first = context.querySelector('input[type=radio], input[type=checkbox]')
    if (!first) { return }

    first.checked = true
    this.selection = [ ...this.selection, first.value ]
  }

  applySelection(context) {
    if (this.selection.length === 0) {
      return this.checkFirst(context)
    }

    const fields = context.querySelectorAll('input[type=radio], input[type=checkbox]')
    Array.from(fields).forEach(e => {
      if (this.selection.indexOf(e.value) > -1) {
        e.checked = true
      }
    })
  }

  _render(title, collection) {
    return renderTemplate(this.templateTarget.innerHTML, { title, collection })
  }

  _load([ option, ...rest ]) {
    if (!option) { return }

    xhr(option.dataset.url)
      .then(r => r.json())
      .then(d => {
        this.render(option.innerText, d)
        this._load(rest)
      })
  }
}
