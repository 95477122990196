function toggleDisabled(tab, state) {
  tab.querySelectorAll(".tab-toggle-disabled").forEach(el => { el.disabled = state })
}

document.addEventListener('click', e => {
  const target = e.target.closest("[data-behavior=tabs]")

  if (!target) {
    return
  }

  const tabs = Array.from(target.parentNode.children)
  const tabsContainer = target.closest(".tabs-container") || target.closest(".tabs").parentNode
  const tabContents = Array.from(tabsContainer.getElementsByClassName("tabs-content"))
  const tabIndex = tabs.indexOf(target)

  tabs.forEach(el => el.classList.remove("is-active"))
  target.classList.add("is-active")

  tabContents.forEach((tab, index) => {
    tab.hidden = tabIndex != index
    toggleDisabled(tab, tabIndex != index)
  })
})
