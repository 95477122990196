import { Controller } from "stimulus"
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

const contentKey = 'data-tippy-content'
const templateKey = 'data-tippy-template'

function getContent(el) {
  if (el.hasAttribute(templateKey)) {
    const id = el.getAttribute(templateKey)
    const template = document.getElementById(id)
    return template.innerHTML
  }

  if (el.hasAttribute(contentKey)) {
    return el.getAttribute(contentKey)
  }
}

export default class extends Controller {
  connect() {
    this.tippy = tippy(this.element, { arrow: true, allowHTML: true, content: getContent })
  }

  disconnect() {
    this.tippy.destroy()
  }
}
