import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "toggleControl", "toggleField" ]

  connect() {
    this.toggle()
  }

  toggle() {
    if (!this.hasToggleControlTarget) return

    this.fieldTargets.forEach(el => this.toggleField(el))
  }

  toggleField(el) {
    if (this.toggleControlTarget.checked) {
      el.setAttribute("disabled", "disabled")
    } else {
      el.removeAttribute("disabled")
    }

    if (this.hasToggleFieldTarget) {
      this.toggleFieldTarget.disabled = !this.toggleControlTarget.checked
    }

    // also set label to disabled
    if (el.tagName == "LABEL" && el.hasAttribute("for")) {
      const elementId = el.getAttribute("for")
      this.toggleField(document.getElementById(elementId))
    }
  }

  reset() {
    if (this.hasToggleControlTarget) {
      this.toggleControlTarget.checked = false
    }
  }
}
