import { Controller } from "stimulus"
import { renderTemplate, showNode, hideNode } from "../utils"

const template = `<select>{{#options}}<option>{{.}}</option>{{/options}}</select>`
const cleanArray = items => items.map(i => i.trim()).filter(String)

export default class extends Controller {
  static targets = [ "field", "container", "example" ]

  connect() {
    this._updateWithValue(this.fieldTarget.value)
  }

  update(e) {
    this._updateWithValue(e.target.value)
  }

  _updateWithValue(value) {
    if (value.indexOf(";") > -1) {
      const options = cleanArray(value.split(";"))
      this.exampleTarget.innerHTML = renderTemplate(template, { options })
      showNode(this.containerTarget)
    } else {
      hideNode(this.containerTarget)
    }
  }
}
