import { Controller } from "stimulus"

function setLocationHash(str) {
  const pos = document.documentElement.scrollTop
  window.location.hash = str
  document.documentElement.scrollTop = pos
}

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:before-visit", this._close)
    if (window.location.hash == "#mta") {
      this._open()
    }
  }

  disconnect() {
    document.removeEventListener("turbo:before-visit", this._close)
  }

  open(e) {
    e.preventDefault()
    this._open()
  }

  close(e) {
    e.preventDefault()
    this._close()
  }

  _open = () => {
    setLocationHash("mta")
    this.element.classList.add("active")
    document.body.parentNode.style.overflowY = "hidden"
  }

  _close = () => {
    setLocationHash("")
    this.element.classList.remove("active")
    document.body.parentNode.style.overflowY = null
  }
}
